<template>
    <div class="main">
        <div class="icons">
            <q-icon
                v-if="chooseCity"
                :name="arrowLeft"
                size="17px"
                v-on:click="chooseCity = false; city = getMainCity()"
                style="color: #DCE1E8;"
                class="cursor-pointer"
            />
        </div>
        <p class="title text-center">{{ getTitle }}</p>
        <div v-if="chooseCity">
            <FormSelect
                width="100%"
                :items="cities"
                v-model="city"
                placeholder="Выберите город из списка"
            />
            <div class="accept-city">
                <FormButton width="100%" :layout="city ? 'primary' : 'secondary'" :disabledStep="true" :city="city" @click="setCity">Выбрать</FormButton>
            </div>
        </div>
        <div v-else class="row accept-city">
            <div class="col-6" style="padding-right: 8px;">
                <FormButton width="100%" layout="secondary" :disabledStep="false" @click="setCity">Да, верно</FormButton>
            </div>
            <div class="col-6" style="padding-left: 8px;">
                <FormButton
                    width="100%"
                    layout="primary"
                    style="float: right;"
                    :disabledStep="false"
                    v-on:click="chooseCity = true; city = null;"
                >Нет, другой</FormButton>

            </div>
        </div>
    </div>
</template>

<script>
import FormButton from './Form/FormButton';
import FormSelect from "./Form/FormSelect";
import { fasChevronLeft } from '@quasar/extras/fontawesome-v6';
import { fire } from "@/helpers/analytics";

/**
 * Component ChooseCity
 * @property Store $store
 */
export default {
    name: "ChooseCity",
    components: {
        FormButton,
        FormSelect
    },
    data() {
        return {
            chooseCity: false,
            cities: [],
            city: null,
            arrowLeft: null,
        }
    },
    methods: {
        getMainCity() {
            const samara = this.cities.filter(city => city.name === 'Самара');
            return samara.length ? samara[0].id : this.cities[0].id;
        },
        nextStep() {
            this.$store.dispatch('data/setRegion', this.city);
            this.$store.dispatch('facilities/set', this.city);
            this.$store.dispatch('parlour/set', this.city);
            this.$store.dispatch('promo/set', this.city);
            this.$store.dispatch('step/setStep', 'ChooseAction');
        },
        setCity() {
            this.nextStep();
            fire("chooseCity", { city: this.city });
        },

    },
    computed: {
        getTitle() {
            return this.chooseCity ? 'Выберите город' : 'Ваш город Самара?';
        }
    },
    beforeMount() {
        this.cities = this.$store.getters['regions/regions'];
        this.city = this.getMainCity();
    },
    created() {
        this.arrowLeft = fasChevronLeft;
    }
}
</script>

<style scoped lang="scss">
.main {
    padding-bottom: 64px;
}

.main>.icons {
    height: 12px;
    padding: 0 6px;
    margin-bottom: 12px;
}

.accept-city {
    margin-top: 40px;
}

.city-select-item {
    font-size: 14px;
    font-weight: 400;
}
</style>