<template>
    <button :disabled="disabledStep && !city" :style="style" :class="[(layout ? layout : 'primary')]">
        {{ text }}
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "FormButton",
    props: [
        'text',
        'width',
        'height',
        'layout', // primary(default)|secondary|disabled|outline
        'padding',
        'fontWeight',
        'city',
        'disabledStep'
    ],
    computed: {
        style () {
            let rule = '';
            if (this.height) { rule += `height: ${this.height};`; }
            if (this.width) { rule += `width: ${this.width};`; }
            if (this.padding) { rule += `padding: ${this.padding};`; }
            if (this.fontWeight) { rule += `font-weight: ${this.fontWeight};`; }
            return rule;
        }
    }
}
</script>

<style scoped lang="scss">
    button {
        border: none;
        border-radius: 40px;
        padding-top: 10px;
        padding-bottom: 10px;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        cursor: pointer;
    }

    button.primary {
        color: white;
        background-color: $primary-color;
    }
    button.primary:hover { background-color: $primary-hover-color; }
    button.primary:active { background-color: $primary-active-color; }

    button.secondary {
        color: black;
        background-color: $secondary-color;
    }
    button.secondary:hover { background-color: $secondary-hover-color; }

    button.disabled {
        cursor: auto;
        background: #FFFFFF;
        border: 1px solid #CFD4DC;
        color: #C0C6CE;
        opacity: 1 !important;
    }

    button.outline {
        border: 1px solid $primary-color;
        border-radius: 100px;
        background: none;
    }

    @media screen and (max-width: $main-width) {
        button {
            font-size: 12px;
        }
    }
</style>