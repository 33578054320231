<template>
    <div class="catalog-body">
<!--        <PromocodeField v-if="getSection === 'service'" class="promo-block" />-->
        <CustomSumField v-if="getSection === 'sum'"  class="promo-block" />
        <CardList class="service-list"/>
    </div>
</template>

<script>
// todo (kimskii): Раскоментить прокод и сделать бэк
import {mapGetters} from "vuex";
// import PromocodeField from "@/components/Catalog/PromocodeField";
import CustomSumField from "@/components/Catalog/CustomSumField";
import CardList from "@/components/Catalog/CardList";

export default {
    name: "CatalogBody",
    components: {
        // PromocodeField,
        CustomSumField,
        CardList
    },
    computed: {
        ...mapGetters({
            getSection: 'catalog/section'
        })
    },
}
</script>

<style scoped>
.catalog-body {
    width: 100%;
}

.service-list {
    margin-top: 16px;
}

.promo-block {
    margin-bottom: 16px;
}

.sale {
  padding: 50px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #616571;
}

@media screen and (max-height: 800px) {
    .catalog-body {
        overflow-y: scroll;
    }
}
</style>
